import React from "react";
import classes from './Home.module.css'
import Carousel from "../../component/Carousle/Carousle";
import Carlist from "./Carlist/Carlist";
import Service from "./Service/Service";
import Footer from "../../component/Footer/Footer";

const Home = props => {
    return (
        <div className={classes.Home}>
            <div className={classes.body}>
              <div className={classes.carousel}><Carousel/></div>
              <div className={classes.Carlist}><Carlist/></div>
              <div className={classes.service}><Service/></div>
            </div>
            {/* <div className={classes.footer}>
            <Footer/>
            </div> */}
        </div>
    );
  }
  
export default Home;
  