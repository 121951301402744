/* eslint-disable no-restricted-globals */
import React, {useState} from "react";
import classes from './MainNavigation.module.css';
import { Link, NavLink } from "react-router-dom";
import CallButton from "../Cards/CallButton/CallButton";
import Logo1 from "../../Images/star car rental.png";
import Hamburger from 'hamburger-react'

const MainNavigation = props => {

    const [isOpen, setOpen] = useState(false)


    return (<>
        {screen.width > 900? 
            <div className={classes.Nav}>
            <Link to="/"><img src={Logo1} alt="Logo" className={classes.img}/></Link>
            
            <div className={classes.Navitems}>
            
                            <NavLink 
                                to=""
                                className={({isActive})=> isActive ? classes.active:""}
                            >
                            Home
                            </NavLink>
                    
                            <NavLink 
                                to="carlist"
                                className={({isActive})=> isActive ? classes.active:""}
                            >
                            Cars
                            </NavLink>
                        
                            <NavLink 
                                to="AboutUS"
                                className={({isActive})=> isActive ? classes.active:""}
                            >
                            AboutUs
                            </NavLink>
            </div>
            <div className={classes.button}>
                                <CallButton
                                    call={true}
                                />
            </div>
            </div>
        :isOpen?
        <div>
            <div className={classes.hambugger}>
                <Hamburger toggled={isOpen} toggle={setOpen}/>
            </div>
        <div className={classes.Nav}>
                <Link to="/"><img src={Logo1} alt="Logo" className={classes.img}/></Link>
                
                <div className={classes.Navitems}>
                
                                <NavLink 
                                    to=""
                                    className={({isActive})=> isActive ? classes.active:""}
                                >
                                Home
                                </NavLink>
                        
                                <NavLink 
                                    to="carlist"
                                    className={({isActive})=> isActive ? classes.active:""}
                                >
                                Cars
                                </NavLink>
                        
                                <NavLink 
                                    to="AboutUS"
                                    className={({isActive})=> isActive ? classes.active:""}
                                >
                                AboutUs
                                </NavLink>
                </div>
                <div className={classes.button}>
                                    <CallButton
                                        call={true}
                                    />
                </div>
        </div>
        </div>
        : <div className={classes.hambugger}>
            <Hamburger toggled={isOpen} toggle={setOpen}/>
          </div>}
        {/* <div className={classes.hambugger}>
            <Hamburger toggled={isOpen} toggle={setOpen}/>
            {isOpen? <div className={classes.sideNav}>
                <Link to="/"><img src={Logo1} alt="Logo" className={classes.img}/></Link>
                
                <div className={classes.Navitems}>
                
                                <NavLink 
                                    to=""
                                    className={({isActive})=> isActive ? classes.active:""}
                                >
                                Home
                                </NavLink>
                        
                                <NavLink 
                                    to="carlist"
                                    className={({isActive})=> isActive ? classes.active:""}
                                >
                                Cars
                                </NavLink>
                        
                                <NavLink 
                                    to="AboutUS"
                                    className={({isActive})=> isActive ? classes.active:""}
                                >
                                AboutUs
                                </NavLink>
                </div>
                <div className={classes.button}>
                                    <CallButton
                                        call={true}
                                    />
                </div>
            </div>:null}
        </div> */}
    </>
    );
  }
  
export default MainNavigation;
  