import React from "react";
import classes from './Skeleton.module.css';
import Symbol from "../Symbol/Symbol";
import Amount from "../Amount/Amount";
import CallButton from "../CallButton/CallButton";
import {GiGearStickPattern,GiCarSeat,GiHorseHead} from 'react-icons/gi';
import {MdLuggage,MdLocalGasStation} from 'react-icons/md';
import {PiGasCanFill} from 'react-icons/pi';

const Skeleton = props => {
    const data = [
       {
            img:<GiGearStickPattern/>, 
            Description:props.transmissionType
        },
        {
            img:<GiCarSeat/>,
            Description:props.sitterNumber
        },
        {
            img: <GiHorseHead/>,
            Description: props.milage
        },
        {
            img: <MdLuggage/>,
            Description: props.luggageNumber
        },
        {
            img: <MdLocalGasStation/>,
            Description: props.fuleType
        },
        {
            img: <PiGasCanFill/>,
            Description: props.fuleCapacity
        }
    ]

    return<>
        <div className={classes.card}>
            <img className={classes.car} src={props.img} alt="car"/>
            <h2 className={classes.carName}>{props.carName}</h2>
            <div className={classes.Amount}>
                <Amount
                value = {props.value}
                />
            </div>
            <div className={classes.callButton}>
                <CallButton
                    call = {true}
                    whatsapp={true}
                />
            </div>
           
            <div className={classes.symbolBox}>
                {data.map( (items,i) => {
                    return <Symbol
                        key = {i}
                        img = {items.img}
                        symbolName = {items.Description}
                    /> 
                })}
            </div>
        </div>
    </>
}

export default Skeleton;