import React from "react";
import { Link } from "react-router-dom";
import classes from './Carlist.module.css';
import Skeleton from "../../../component/Cards/Skeleton/Skeleton";
import Hyundai from "../../../Images/hyundai-white-creta-car.jpg";
import Swift from '../../../Images/Maruti-Suzuki-Swift.jpg';
import Baleno from '../../../Images/maruti-baleno-exterior.jpg';

const Carlist = props => {
    const list = [
        {
        img:Hyundai,
        carName:"Hyundai Creata (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"19 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "55 Ltr",
        value:"2800",
      },
      {
        img:Swift,
        carName:"Swift (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"16 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1200",
      },
      {
        img:Baleno,
        carName:"Belano (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"18 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "40 Ltr",
        value:"1200",
      },
      {
        img:Swift,
        carName:"Swift (Automatic)",
        transmissionType: "Automatic",
        sitterNumber:"5 seater",
        milage:"16 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1500",
      },
      {
        img:Baleno,
        carName:"Belano (Automatic)",
        transmissionType: "Automatic",
        sitterNumber:"5 seater",
        milage:"18 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "40 Ltr",
        value:"1500",
      },
    ]
    return(
        <div>  
            <h1 className={classes.head}>High <span className={classes.span}>Demanded</span> Cars</h1>
            {list.map((items,i)=>{
            return(
              <div key={i} className={classes.Card}>
                <Skeleton
                  img={items.img}
                  carName={items.carName}
                  transmissionType={items.transmissionType}
                  sitterNumber={items.sitterNumber}
                  milage={items.milage}
                  luggageNumber={items.luggageNumber}
                  fuleType={items.fuleType}
                  fuleCapacity={items.fuleCapacity}
                  value = {items.value}
                />
              </div>
                )
            })}
            <Link to="carlist">
              <button className={classes.Button}>View More</button>
            </Link>
        </div>
    )
}

export default Carlist;