import React from "react";
import classes from './TermsCondition.module.css';
import Footer from "../../component/Footer/Footer";

const TermsCondition = props => {
    return(<>
        <div className={classes.container}>
            <h1 className={classes.head}>Terms & Conditions</h1>
            <h2>Timings</h2>
            <p className={classes.para}>Return time for the car is before 8 am on the day of return. The 24r hours of car rental count from 9 am to the next day 8 am. If late charges will be applied for that day too.</p>
            <h2>Security</h2>
            <p className={classes.para}>For verification purposes, we will keep 1 original photo ID on file.Please carry your valid driving license as per issued by Govt. of India.</p>
            <h2>No Outstation Trip</h2>
            <p className={classes.para}>All of our self-drive cars are permitted to drive in GOA state only so No vehicles should leave Goa without telling the management first. All of the cars have valid black plates issued by Goa govt. to drive self-drive cars in goa.</p>
            <h2>Damages and fees</h2>
            <p className={classes.para}>All customers should make a video of the car before they take possession of it. This shows what kind of shape the car is in. Any damage that was caused by accident will be charged to the customer, based on what the showroom inspection found.</p>
            <h2>Payment & Refund Policy</h2>
            <p className={classes.para}>We do not take any direct payment on the Royal Car Rental Goa website by any medium like debit card/credit card, etc and don’t store any information of users on the website database. We maintain our customer’s privacy and respect for that.</p>
            <h5 className={classes.para}>No part of the website or its content, including but not limited to graphics, logos, identities, images, and content, should be copied in any digital or physical format without legal permission from Royal Car Rental Goa. If you want to talk to us about legal issues, permissions, or anything else, fill out the form on our contact page and put “Legal Matter” in the subject line. All of the content and graphics/images that Royal Car Rental Goa makes are copyrighted and can only be used with permission, according to laws about intellectual property.</h5>
            <h2>Deposit Policy</h2>
            <p className={classes.para}>3000 to 5000 rupee's depending on the car will be taken as deposit and it will be refunded once car is handed over back to agency.</p>
            <h2>Note:</h2>
            <p className={classes.para}>In Goa, it is against the rules to drive a car on the beach, and doing so is a crime.</p>
            
        </div>
        <div className={classes.footer}>
            <Footer/>
        </div>
        
    </>)
}

export default TermsCondition;