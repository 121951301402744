import React from "react";
import classes from './Service.module.css'
import Dabolim from '../../../Images/Dabolim_Airport.jpg';
import Mopa from '../../../Images/Mopa_Airport.jpg';
 
const Service = props => {
    return(<>
        <div className={classes.container}>
            <h1 className={classes.head}>Pickup & Drop Services at <span className={classes.span}>Goa Airports</span></h1>
            <p className={classes.para}>Royal Car Rentals is just a call away if you are looking for a convenient and hassle-free trip just after landing at Goa Airport.</p>
            <div className={classes.Mopa}>
                <img className={classes.img} src={Mopa} alt="Mopa_image"/>
                <div className={classes.points}>
                    <h1>Car Rental at Mopa Airport</h1>
                    <ul>
                        <li>On-demand pickup & drop services</li>
                        <li>24x7 hours support with us</li>
                        <li>Well-maintained cars at Mopa Airport</li>
                    </ul>
                </div>
            </div>
            <div className={classes.Dabolim}>
                <img className={classes.img} src={Dabolim} alt="Dabolim_image"/>
                <div className={classes.points}>
                    <h1>Car Rental at Dabolim Airport</h1>
                    <ul>
                        <li>On-demand pickup & drop services</li>
                        <li>24x7 hours support with us</li>
                        <li>Well-maintained cars at Dabolim Airport</li>
                    </ul>
                </div>
            </div>
        </div>
    </>)
}

export default Service