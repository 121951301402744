import React from "react";
import classes from "./Cars.module.css"
import Skeleton from "../../component/Cards/Skeleton/Skeleton";
import DescriptionCar from "./DescriptionCar/DescriptionCar";
import Hyundai from "../../Images/hyundai-white-creta-car.jpg";
import Swift from "../../Images/Maruti-Suzuki-Swift.jpg";
import Baleno from "../../Images/maruti-baleno-exterior.jpg";
import i10 from "../../Images/grand-i10.webp";
import Thar from "../../Images/Thar.jpeg";
import Ertiga from "../../Images/Ertiga.jpeg";
import Swift_Dzire from "../../Images/Maruti-Suzuki-New-Dzire.webp";
import InovaCrystra from "../../Images/Inova_crysta.jpg";
import Breeza from "../../Images/maruti-brezza.jpg";
import Cranes from "../../Images/Kia_Carens.jpg"
import Footer from "../../component/Footer/Footer";

const Cars = props => {
    const carlist = [
      {
        img:Hyundai,
        carName:"Hyundai Creata (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"19 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "55 Ltr",
        value:"2800",
      },
      {
        img:Hyundai,
        carName:"Hyundai Creata (Automatic)",
        transmissionType: "Automatic",
        sitterNumber:"5 seater",
        milage:"19 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "55 Ltr",
        value:"3500",
      },
      {
        img:Swift,
        carName:"Swift (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"16 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1200",
      },
      {
        img:Baleno,
        carName:"Belano (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"18 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "40 Ltr",
        value:"1200",
      },
      {
        img:Swift,
        carName:"Swift (Automatic)",
        transmissionType: "Automatic",
        sitterNumber:"5 seater",
        milage:"16 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1500",
      },
      {
        img:Baleno,
        carName:"Belano (Automatic)",
        transmissionType: "Automatic",
        sitterNumber:"5 seater",
        milage:"18 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "40 Ltr",
        value:"1500",
      },
      {
        img:Ertiga,
        carName:"Maruti Suzuki Ertiga",
        transmissionType: "Manual",
        sitterNumber:"7 seater",
        milage:"19 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2000",
      },
      {
        img:i10,
        carName:"Hyundai i10 (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"17 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1000",
      },
      {
        img:Thar,
        carName:"Mahindra Thar",
        transmissionType: "Manual",
        sitterNumber:"6 seater",
        milage:"12 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "50 Ltr",
        value:"3800",
      },
      {
        img:Thar,
        carName:"Mahindra Thar",
        transmissionType: "Automatic",
        sitterNumber:"6 seater",
        milage:"12 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "50 Ltr",
        value:"4300",
      },
      {
        img:Swift_Dzire,
        carName:"swift Dzire (Manual)",
        transmissionType: "Manual",
        sitterNumber:"5 seater",
        milage:"18 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1300",
      },
      {
        img:InovaCrystra,
        carName:"Innova Crysta",
        transmissionType: "Manual",
        sitterNumber:"7 Seater",
        milage:"12 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "65L",
        value:"3500",
      },
      {
        img:Breeza,
        carName:"Breeza(SUV)",
        transmissionType: "Manual",
        sitterNumber:"5 Seater",
        milage:" 18km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "50L",
        value:"2600",
      },
      {
        img:Cranes,
        carName:"Kia Cranes",
        transmissionType: "Manual",
        sitterNumber:"7 Seater",
        milage:" 16km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45L",
        value:"2500",
      },
    ]

    return (
    <div className={classes.container}>
      <div className={classes.car}>
        {/* <div className={classes.DescriptionCar}>
          <DescriptionCar/>
        </div> */}
        {carlist.map((items,i)=>{
            return(
              <div key={i} className={classes.Card}>
                <Skeleton
                  img={items.img}
                  carName={items.carName}
                  transmissionType={items.transmissionType}
                  sitterNumber={items.sitterNumber}
                  milage={items.milage}
                  luggageNumber={items.luggageNumber}
                  fuleType={items.fuleType}
                  fuleCapacity={items.fuleCapacity}
                  value = {items.value}
                />
              </div>
            )
          })}
      </div>
    </div>
    );
  }
  
export default Cars;
  