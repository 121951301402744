import React from "react";
import classes from './AboutCard.module.css';
import { IconContext } from "react-icons";

const AboutCard = props => {
    return <>
        <div className={classes.Card}>
            <div className={classes.top}>
                <h3>{props.head}</h3>
                <p>{props.paragraph}</p>
            </div>
            <div className={classes.symbol}>
                <IconContext.Provider value={{ className: classes.circle }}>
                    <div>
                      {props.icon}
                    </div>
                  </IconContext.Provider>
              </div>
            <div className={classes.bottom}></div>
        </div>
    </>
}

export default AboutCard;