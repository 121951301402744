import React from "react";
import Classes from './Symbol.module.css';
import { IconContext } from "react-icons";

const Symbol = props => {
    return <div className={Classes.container}>
        <IconContext.Provider value={{ className: Classes.symbol}}>{props.img}</IconContext.Provider>
        <p className={Classes.symbolName}>{props.symbolName}</p>
    </div>
}

export default Symbol