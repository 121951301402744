import React from "react";
import classes from './callbutton.module.css';
import { Link } from "react-router-dom";
import {MdOutlineWifiCalling3} from "react-icons/md";
import {BsWhatsapp} from "react-icons/bs";


const CallButton = props => {
    return<>
        {props.call?  <button className={classes.Callbutton}>
            <Link to="tel:9834844251" className={classes.link}>
                <div className={classes.call}>
                    <MdOutlineWifiCalling3/>
                    <p className={classes.text}>Call to Book</p>
                </div>
            </Link> 
        </button>:null}
        {props.whatsapp? <button className={classes.Whatsappbutton}><Link to="https://wa.me/9834844251" className={classes.link}>
                <div className={classes.call}>
                    <BsWhatsapp/>
                    <p className={classes.text}>Whatsapp Me</p>
                </div>
        </Link> </button>:null}
    </>
}

export default CallButton;