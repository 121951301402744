import React from "react";
import classes from './Footer.module.css';
import Logo from '../../Images/star car rental.png';
import { IconContext } from "react-icons";
import {IoIosCall} from "react-icons/io";
import {MdEmail} from "react-icons/md";
import {GrMapLocation} from "react-icons/gr";
import { Link } from "react-router-dom";

const Footer = props => {
    return(
        <div className={classes.footer}>
            <div className={classes.Container}>
                <img className={classes.img} src={Logo} alt="Logo"/>
                <div className={classes.contact}>
                    <h2 className={classes.Contactheading}>Contact</h2>
                    <a className={classes.phonelink} href="tel:9762137286">
                        <div className={classes.onHover}>
                            <IconContext.Provider value={{ className: classes.phone }}>
                                <IoIosCall/>
                            </IconContext.Provider>
                            <p className={classes.phonenumber}>+91 976-213-7286</p>
                        </div>
                    </a>
                    <a className={classes.emaillink} href="mailto:saifshaikh.5224@gmail.com">
                        <div className={classes.onHover}>
                            <IconContext.Provider value={{ className: classes.mail }}>
                                <MdEmail/>
                            </IconContext.Provider>
                            <p className={classes.email}>saifshaikh.5224@gmail.com</p>
                        </div>
                    </a>
                    <div className={classes.addresscont}>
                        <IconContext.Provider value={{ className: classes.location }}>
                            <GrMapLocation/>
                        </IconContext.Provider>
                        <p className={classes.address}><Link className={classes.link} to="https://maps.app.goo.gl/CUgWrUQYvRPtPm3X8">8/7  star car rentals near railway station Vasco Da Gama goa 403902</Link></p>
                    </div>
                </div>
                <div className={classes.copyright}>
                    <p>
                        © Copyright 2023 All Rights Reserved | No part of the website or it's contents including but not limited to graphics, logo, identity, images, contents should be copied in any digital or physical format without taking proper legal permissions from Star Car Rental Goa. 
                        To discuss anything related to legal matters, permissions and otherwise, get in touch with us &amp; mention Legal Matter in subject line. 
                        All contents and graphics/images produced by Star Car Rental Goa are copyrighted under proper legalities and will require permission before usage as per intellectual property laws.
                    </p>
                    <div className={classes.TermPrivacy}>
                        <Link to="TermsandCondition" className={classes.link}><p>Terms & Condition|</p></Link>
                        <Link to="PrivacyPolicy" className={classes.link}><p className={classes.link}>Privacy Policy</p></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;