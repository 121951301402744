import React from "react";
import classes from './Amount.module.css';
import { IconContext } from "react-icons";
import {FaRupeeSign} from 'react-icons/fa';

const Amount = props => {
    return<>
    <div className={classes.container}>
        <p className={classes.words}>Starting From</p>
        <div className={classes.Amount}>
            <div className={classes.symbol}>
                <IconContext.Provider value={{ className: classes.rupee}}>
                    <FaRupeeSign/>
                </IconContext.Provider>
            </div>
            <p className={classes.value}> {`${props.value}/ day`}</p>
        </div>
    </div>
    </>
}

export default Amount