import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Car1 from '../../Images/hyundai-white-creta-car.jpg';
import Car2 from '../../Images/Thar.jpeg';
import Car3 from '../../Images/roadtrip-1583526313-1.jpg';
import Car4 from '../../Images/086-suzuki-swift.jpg'
import classes from './carousle.module.css'


const Carouselfun = props =>{
    
    var items = [
        {
            name: "Car1",
            img: Car1
        },
        {
            name: "Car2",
            img: Car2
        },
        {
            name: "Car3",
            img: Car3
        },
        {
            name: "Car4",
            img: Car4
        }
    ]

    return <>
        <div>
            <h1 className={classes.head}>Rent.Ride.Rejoice</h1>
            <p className={classes.para}>Really in the mood for a holiday or a long drive with no real destination</p>
            <Carousel stopAutoPlayOnHover={false} className={classes.carousel}>
                    {
                        items.map( (item, i) => {
                            return  <div key={i} className={classes.img}>
                                        <img key={i} className={classes.imgsize} src={item.img} alt={item.name}/>
                                    </div>
                                })
                    }
            </Carousel>
        </div>
    </>
}

export default Carouselfun;