import React from "react";
import classes from "./About.module.css"
import Nature from "../../Images/summer-car-care.jpeg"
import Logo from '../../Images/star car rental.png'
import Support from "../../Images/24-hour-7-day.jpg";
import AboutCard from "./AboutCard/AboutCard";
import Footer from "../../component/Footer/Footer";
import  {AiFillCar} from "react-icons/ai";
import  {MdSendToMobile} from "react-icons/md";
import { IconContext } from "react-icons";
import {AiFillSafetyCertificate} from 'react-icons/ai';
import {GiCoins} from 'react-icons/gi';
import {BiSupport} from 'react-icons/bi';


const About = props => {
  const card = [
    {
      head:"Quality Service",
      para:"Royal Car Rentals guarantees you the best quality service in self drive car, bikes and sightseeing.",
      icon: <AiFillSafetyCertificate/>
    },
    {
      head:"Competitive Pricing",
      para:"We provide an affordable price in the market which includes Taxes. No hidden charges!",
      icon: <GiCoins/>
    },
    {
      head:"Trustable Support",
      para:"In case of any support with our service at any serviceable time, we are always a call away.",
      icon: <BiSupport/>
    }
  ]
    return (<div className={classes.container}>
      <div className={classes.About}>
       
          <img src={Logo} alt="image" className={classes.logo}/>
          <img src={Nature} alt="Image" className={classes.img} />
      
        <div className={classes.text}>
          <h1>We want <span>our Clients</span> to be happy</h1>
          <p>
            Our mission at Star Car Rentals is to make your entire travel experience as joyful and stress-free as possible from the moment you land in the Goa. 
            We work hard to give our customers the best service possible. 
            Our team is always ready to answer your questions and give you tips on how to get the most out when you hire self drive vehicle with us.
          </p>
          <p>
            At Star Car Rental, we assure you that we will provide a quality fleet, our cars are well-maintained, sanitized, and comfortable to use. 
            We are the most trusted, best, and most affordable<strong>car rental service in Goa</strong>. 
            We assure you a pleasure ride while availing of our self-drive car rental services in Goa.
          </p>
        </div>
        <div className={classes.points}>
            <div className={classes.points1}>
              <div className={classes.circle}><img className={classes.Circleimg} src={Support} alt="Image"/></div>
              <div className={classes.Box}><h2>24/7 Customer Support Service</h2></div>
            </div>
            <div className={classes.points2}>
              <div>
                <IconContext.Provider value={{ className: classes.circle }}>
                  <div>
                    <AiFillCar />
                  </div>
                </IconContext.Provider>
              </div>
              <div className={classes.Box}><h2>Well Maintained Cars</h2></div>
            </div>
            <div className={classes.points3}>
              <div>
                <IconContext.Provider value={{ className: classes.circle }}>
                    <div>
                      <MdSendToMobile />
                    </div>
                  </IconContext.Provider>
              </div>
              <div className={classes.Box}><h2>Hassle Free Bookings</h2></div>
            </div>
        </div>
        <div className={classes.AboutCard}>
          {card.map((items, i) => {
            return(<div key={i}>
              <AboutCard
                head={items.head}
                paragraph ={items.para}
                icon ={items.icon}
              />
            </div>)
          })}
        </div>
      
        {/* <div className={classes.footer}><Footer/></div> */}
        
      </div>
    
    </div>
    );
  }
  
export default About;
  