import React from "react";
import classes from './RootLayout.module.css';
import {Outlet} from "react-router-dom"; //Outlet points out where the children routes should be placed
import MainNavigation from "../../component/Layout/MainNavigation";
import Footer from "../../component/Footer/Footer";

const RootLayout = props => {
    return (
    <div className={classes.body}>
        <div className={classes.container}>
            <MainNavigation/>
            <div className={classes.Outlet}><Outlet/></div>
        </div>
        <div className={classes.footer}><Footer/></div>
    </div>
    );
}

export default RootLayout